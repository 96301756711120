import {
    CheckCircleFilled,
    CloseCircleFilled,
    LinkOutlined,
} from '@ant-design/icons';
import { Flex, List, Space, Typography } from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
    PayrollEmployeeCheckDto,
    PaginatedResponseOfShiftListItemDto,
} from '@clh/api-client';
import { calculateTotalPay, calculateTotalStipend } from '@clh/util';

import { useApiClient } from '../hooks/use-api-client';
import { useSelection } from '../hooks/use-selection';
import ObjectPropertyList from '../record-details/object-property-list';

import { PAYROLL_STATUS_CONFIG } from './payroll-status-config';

export default function Details() {
    const api = useApiClient();
    const { selection } = useSelection<PayrollEmployeeCheckDto>();
    const [shifts, setShifts] = useState<PaginatedResponseOfShiftListItemDto>();

    const fetchShifts = async () => {
        const result = await api!.shiftControllerListShifts({
            search: selection?.zealEmployeeCheckId,
            pageSize: 100,
        });
        setShifts(result);
    };

    useEffect(() => {
        if (api && selection?.zealEmployeeCheckId) {
            void fetchShifts();
        }
    }, [api, selection?.zealEmployeeCheckId]);

    const formatCurrency = (amount: number) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        }).format(amount / 100);

    const totalGrossPay = useMemo(
        () =>
            shifts?.result.reduce(
                (total, shift) =>
                    total +
                    calculateTotalPay(
                        shift.payRate,
                        shift.minutesWorked!,
                        calculateTotalStipend(
                            shift.stipends?.filter((s) => s.qualified)
                        )
                    ),
                0
            ),
        [shifts]
    );

    if (!api || !selection) {
        return null;
    }
    return (
        <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Typography.Title level={4}>Paycheck</Typography.Title>
            <ObjectPropertyList
                values={selection}
                fields={{
                    zealEmployeeCheckId: (id: string) => (
                        <Space direction="vertical">
                            <Typography.Text code copyable>
                                {id}
                            </Typography.Text>
                            {selection.status === 'PENDING' && (
                                <div style={{ textAlign: 'right' }}>
                                    <a
                                        href={`https://payroll.humla.com/pending-checks/check/${id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <LinkOutlined /> Open in Zeal
                                    </a>
                                </div>
                            )}
                        </Space>
                    ),
                    employeeName: (name: string) => (
                        <Link
                            to={`/profiles/${selection.profileId}`}
                            target="_blank"
                        >
                            {name}
                        </Link>
                    ),
                    grossPay: (amount: number | null) => (
                        <>
                            {amount !== null
                                ? new Intl.NumberFormat('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                  }).format(amount / 100)
                                : '-'}
                        </>
                    ),
                    netPay: (amount: number | null) => (
                        <>
                            {amount !== null
                                ? new Intl.NumberFormat('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                  }).format(amount / 100)
                                : '-'}
                        </>
                    ),
                    checkDate: (date: Date | null) => (
                        <>
                            {date
                                ? DateTime.fromJSDate(
                                      new Date(date)
                                  ).toLocaleString(DateTime.DATE_SHORT)
                                : '-'}
                        </>
                    ),
                    status: (status: string) => {
                        const config = PAYROLL_STATUS_CONFIG[status] || {
                            backgroundColor: '#f5f5f5',
                            text: status,
                        };
                        return (
                            <span
                                style={{
                                    backgroundColor: config.backgroundColor,
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                }}
                            >
                                {config.text}
                            </span>
                        );
                    },
                    isApproved: (value: boolean) =>
                        value ? (
                            <CheckCircleFilled style={{ color: '#52c41a' }} />
                        ) : (
                            <CloseCircleFilled style={{ color: '#ff4d4f' }} />
                        ),
                    processedAt: (date: Date | null) => (
                        <>
                            {date
                                ? DateTime.fromJSDate(
                                      new Date(date)
                                  ).toLocaleString(DateTime.DATE_SHORT)
                                : '-'}
                        </>
                    ),
                    createdAt: (date: Date) => (
                        <>
                            {DateTime.fromJSDate(new Date(date)).toLocaleString(
                                DateTime.DATE_SHORT
                            )}
                        </>
                    ),
                    updatedAt: (date: Date) => (
                        <>
                            {DateTime.fromJSDate(new Date(date)).toLocaleString(
                                DateTime.DATE_SHORT
                            )}
                        </>
                    ),
                    shifts: (shifts: string[]) => <>{shifts.length}</>,
                }}
            />

            <Typography.Title level={4}>Shifts</Typography.Title>
            <List
                dataSource={shifts?.result ?? []}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Total Gross Pay:{' '}
                            {totalGrossPay
                                ? formatCurrency(totalGrossPay)
                                : '--'}
                        </Typography.Title>
                    </div>
                }
                renderItem={(shift) => (
                    <List.Item style={{ alignItems: 'start' }}>
                        <Flex vertical>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                {shift.facility.name}
                            </Typography.Title>
                            <Typography.Text type="secondary">
                                {DateTime.fromJSDate(
                                    shift.startTime
                                ).toLocaleString(DateTime.DATETIME_SHORT)}{' '}
                                -{' '}
                                {DateTime.fromJSDate(
                                    shift.endTime
                                ).toLocaleString(DateTime.DATETIME_SHORT)}
                            </Typography.Text>
                            <Typography.Text code>
                                <Link
                                    to={`/shifts/${shift.id}`}
                                    target="_blank"
                                >
                                    {shift.id}
                                </Link>
                            </Typography.Text>
                        </Flex>
                        <div style={{ textAlign: 'right' }}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Gross Pay:{' '}
                                {formatCurrency(
                                    calculateTotalPay(
                                        shift.payRate,
                                        shift.minutesWorked!,
                                        calculateTotalStipend(
                                            shift.stipends?.filter(
                                                (s) => s.qualified
                                            )
                                        )
                                    )
                                )}
                            </Typography.Title>
                            <Typography.Text type="secondary">
                                {formatCurrency(shift.payRate)}/hr ×{' '}
                                {(shift.minutesWorked! / 60).toLocaleString(
                                    'US',
                                    { maximumFractionDigits: 2 }
                                )}{' '}
                                hours
                            </Typography.Text>
                            {shift.stipends
                                ?.filter((s) => s.qualified)
                                .map((stipend, index) => (
                                    <div key={index}>
                                        <Typography.Text type="secondary">
                                            + {formatCurrency(stipend.amount)}{' '}
                                            stipend
                                        </Typography.Text>
                                    </div>
                                ))}
                        </div>
                    </List.Item>
                )}
            />
        </Space>
    );
}
